import React from "react";
import { CircularProgress, LinearProgress } from "@mui/material";

const Loader = (props: any) => {
  const { linear, size } = props;
  return linear ? (
    <LinearProgress className="uiLoader" />
  ) : (
    <CircularProgress className="uiLoader" size={size || 30} thickness={4} />
  );
};

export default Loader;
