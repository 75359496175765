import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";

const SwitchBtn = (props: any) => {
  const { id, size, checked, text, onChange } = props;
  const [value, setValue] = useState(checked || false);
  const onChangeValue = (e: any) => {
    if (onChange) {
      onChange(e.target.checked);
    }
    setValue(e.target.checked);
  };
  return (
    <FormControlLabel
      className="uiSwitchBtn"
      control={<Switch id={id} checked={value} size={size || "medium"} />}
      label={text}
      onChange={onChangeValue}
    />
  );
};

export default SwitchBtn;
