import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonBox = (props: any) => {
  const { width, height, style } = props;
  return (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={width || "100%"}
      height={height}
      style={style || {}}
    />
  );
};

export default SkeletonBox;
