import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";

import App from "./App";

import store from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// todo: Use babel plugin for this
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

root.render(
  <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
      <App />
    </Provider>
  </SnackbarProvider>
);
