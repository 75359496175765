import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

const ChkBox = (props: any) => {
  const { id, disabled, text, checked, onChange } = props;
  const [checkedValue, setCheckedValue] = useState(checked || false);
  const onChangeValue = () => {
    setCheckedValue(!checkedValue);
    if (onChange) {
      onChange(!checkedValue);
    }
  };
  return (
    <FormControlLabel
      className="uiChkBox"
      checked={checkedValue}
      disabled={disabled}
      control={<Checkbox id={id} />}
      label={text}
      onChange={onChangeValue}
    />
  );
};

export default ChkBox;
