import { configureStore } from "@reduxjs/toolkit";

import chartReducer from "../reducers/chartReducer";

const logger = require("redux-logger");
const loggerMiddleware = logger.createLogger();

const store = configureStore({
  reducer: {
    chartReducer,
  },
  middleware: (getDefaultMiddleware) => {
    // For Default Middlewares we append our custom middleware
    return getDefaultMiddleware().concat(loggerMiddleware);
  },
});

export default store;
